 import React,{useState} from 'react';
// import Avatar from "@material-ui/core/Avatar";
// import Button from "@material-ui/core/Button";
 import CssBaseline from "@material-ui/core/CssBaseline";
 import TextField from "@material-ui/core/TextField";
 import FormControlLabel from "@material-ui/core/FormControlLabel";
 import { Button } from 'react-scroll';
 import Checkbox from "@material-ui/core/Checkbox";
 //import Link from "@material-ui/core/Link";
 import Grid from "@material-ui/core/Grid";
 import Box from "@material-ui/core/Box";
 //import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
 //import Typography from "@material-ui/core/Typography";
 import { makeStyles } from "@material-ui/core/styles";
 import Container from "@material-ui/core/Container";
 //import { withStyles } from "@material-ui/core/styles";
 //import Card from "@material-ui/core/Card";
 //import CardActionArea from "@material-ui/core/CardActionArea";
 //import CardContent from "@material-ui/core/CardContent";
 import axios from 'axios';
 
const useStyles = makeStyles((theme) => ({
  hero: {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQ0TTV44zm-NVhEDyFKopLLAsqg5dxkr451A&usqp=CAU')`,
    height: "500px",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#fff",
    fontSize: "3rem",
    [theme.breakpoints.down("sm")]: {
      height: 300,
      fontSize: "3em",
    },
  },
  root: {
    // display: "flex",
    position: "center",
    justifyContent: "center",
    alignItems: "center",

    fontSize: "4rem",
    [theme.breakpoints.down("sm")]: {
      height: 300,
      fontSize: "4rem",
    },
    // maxWidth: "80%",
  },
  media: {
    height: 200,
  },

  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));


export default function LoanRequestPage() {
  const classes = useStyles(); 
  const [firstName,setfirstName] = useState("");
  const [lastName,setlastName] = useState("");
  const [phone,setphone] = useState("");
  const [email,setemail] = useState("");
  const [city,setcity] = useState("");
  const [pincode,setpincode] = useState("");
  const [radioValue,onChangeSelect] = useState("");
  // const [radioValue, setRadioValue] = useState(0);
  // // const onChangeSelect = (ev) => {
  // //   //save your value here with state variable
  // //   console.log(ev.target.value);
  // //   setRadioValue(ev.target.value);
  // // };

  const submitPost = () => {
    axios.post('http://localhost:3003/api/create', {selectedOption: radioValue, firstName: firstName, lastName: lastName, phone:phone, email:email, city:city, pincode:pincode})
    .then(res => {
      if (res.data === "success"){
        alert("Success! Rectify team will get in touch with you");
      }else{
        alert("Error! please connect with abc@rectifycredit.com");
      }
    });
  }

  return (
    <div>
     <Box className={classes.hero}>
        <Box>
            Loan Request 
        </Box>
        {/* <Box>reliable experts in rebuilding your Credit Score </Box> */}
      </Box>
      {/* <Container>
        <Card className={classes.root}>
          <CardActionArea>
            <CardContent>
              <WhiteTextTypography variant="h3"  align="center" className={classes.blogTitle} gutterBottom >
                You Want to Know?
              </WhiteTextTypography>
              <Typography variant="body2" color="black" component="p">
                Why your loans are getting Rejected? Why your credit card got
                Declined? Can you apply for a housing loan? Will you get an Auto
                loan? How and where to apply for CIBIL Report (or for Experian /
                Equifax/ Criff Highmark)?
              </Typography>

              <Typography variant="body2" color="black" component="p">
                Any other queries regarding your Credit score? Register at our
                web site for these questions and many more like CIBIL, Credit
                Scores, Credit Report, Credit Building, Credit planning and
                Credit protection.
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Container> */}

      <Container component="main" maxWidth="md">
        <CssBaseline />

        <div className={classes.paper}>
          {/* <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Register Here
          </Typography> */}
           <form className={classes.form} noValidate action='#' >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField autoComplete="fname" name="firstName" variant="outlined" required fullWidth id="firstName" label="First Name" autoFocus onChange={(e)=> {setfirstName(e.target.value)}} />
              </Grid> 
              <Grid item xs={12} sm={6}>
                <TextField variant="outlined" required fullWidth id="lastName" label="Last Name" name="lastName" autoComplete="lname" onChange={ (e)=> {setlastName(e.target.value)}}  />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField variant="outlined" required fullWidth id="phone" label="Phone" name="phone" autoComplete="phone" type="tel"  onChange={ (e)=> {setphone(e.target.value)}}   />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField variant="outlined" required fullWidth id="email" label="Email Address" name="email" autoComplete="email"  onChange={ (e)=> {setemail(e.target.value)}}  />
              </Grid> 

              <Grid item xs={12} sm={6}>
                <TextField variant="outlined" required fullWidth id="city" label="City" name="city" autoComplete="city" onChange={ (e)=> {setcity(e.target.value)}}   />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField variant="outlined" required fullWidth id="pincode" label="Pincode" name="pincode" autoComplete="pincode"  onChange={ (e)=> {setpincode(e.target.value)}}   />
              </Grid>

               <Grid item xs={12} sm={6}>
               <div className="radio">
                <label>
                  <input type="radio" name="loanOption" noChange={(e)=> {onChangeSelect(e.target.value)}} value="1" checked={true} />
                  Personal loan
                </label>
              </div>
              <div className="radio">
                <label>
                  <input type="radio" name="loanOption" noChange={(e)=> {onChangeSelect(e.target.value)}} value="2" />
                  SME loan
                </label>
              </div>
              </Grid>  
               <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox value="allowExtraEmails" color="primary" />
                  }
                  label="I want to receive inspiration, marketing promotions and updates via email."
                />
              </Grid>
            </Grid>
            <Button fullWidth variant="contained" onClick={submitPost}   color="primary" className={classes.submit}>Submit Request</Button>
          </form> 
        </div>  
      </Container> 
    </div>
  );
}
